document.addEventListener('DOMContentLoaded', () => {
  // Color mode

  const toggleColorModeLink: HTMLElement | null = document.getElementById("toggle-color-mode");

  const setTheme = (theme: 'light' | 'dark') => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("color-mode", theme);
  };

  const toggleColorMode = () => {
    const currentTheme = localStorage.getItem("color-mode");
    const newTheme = currentTheme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  toggleColorModeLink?.addEventListener("click", (event) => {
    event.preventDefault();
    toggleColorMode();
  });

  // Initialize the theme on page load
  const savedTheme = localStorage.getItem("color-mode") || "light";
  setTheme(savedTheme as 'light' | 'dark');
});

